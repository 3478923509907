exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-accessible-modals-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/accessible-modals.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-accessible-modals-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-changing-course-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/changing-course.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-changing-course-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-closing-out-2022-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/closing-out-2022.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-closing-out-2022-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-death-to-the-sidebar-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/death-to-the-sidebar.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-death-to-the-sidebar-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-designing-for-an-election-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/designing-for-an-election.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-designing-for-an-election-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-firstname-lastname-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/firstname-lastname.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-firstname-lastname-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-getting-local-with-android-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/getting-local-with-android.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-getting-local-with-android-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-getting-the-right-image-handling-and-writing-workflow-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/getting-the-right-image-handling-and-writing-workflow.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-getting-the-right-image-handling-and-writing-workflow-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-i-am-not-tracking-you-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/i-am-not-tracking-you.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-i-am-not-tracking-you-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-i-have-website-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/i-have-website.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-i-have-website-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-introducing-corpora-translations-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-corpora-translations.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-introducing-corpora-translations-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-its-so-simpl-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/its-so-simpl.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-its-so-simpl-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-macawesome-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/macawesome.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-macawesome-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-make-the-frame-work-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/make-the-frame-work.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-make-the-frame-work-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-now-using-jekyll-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/now-using-jekyll.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-now-using-jekyll-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-recipe-a-styleguide-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/recipe-a-styleguide.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-recipe-a-styleguide-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-redesigning-in-the-open-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/redesigning-in-the-open.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-redesigning-in-the-open-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-responsive-html-tables-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/responsive-html-tables.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-responsive-html-tables-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-the-new-politico-pro-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/the-new-politico-pro.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-the-new-politico-pro-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-the-waiting-game-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/blog/the-waiting-game.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-blog-the-waiting-game-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-24-hours-in-manhattan-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/24-hours-in-manhattan.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-24-hours-in-manhattan-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-cape-town-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/cape-town-2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-cape-town-2023-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-iceland-2018-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/iceland-2018.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-iceland-2018-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-ireland-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/ireland-2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-ireland-2023-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-rome-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/rome-2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-rome-2023-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-toronto-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/content/photos/toronto-2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-jsx-content-file-path-src-content-photos-toronto-2023-mdx" */),
  "component---src-pages-photos-jsx": () => import("./../../../src/pages/photos.jsx" /* webpackChunkName: "component---src-pages-photos-jsx" */)
}

